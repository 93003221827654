import 'keen-slider/keen-slider.min.css'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { Spinner } from 'app/components/Common/Spinner'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

const AUTOPLAY_TIMER = 5000

export interface Props {
  claim?: string
  images?: ImageProps[]
  mobileImage?: ImageProps
  payoff?: string
  variant?: Variant
}

export const Hero = memo(function Hero({
  claim,
  images,
  mobileImage,
  payoff,
  variant = 'default',
}: Props) {
  if (!images) {
    return null
  }

  if (images.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [loaded, setLoaded] = React.useState<boolean[]>([])
  const [opacities, setOpacities] = React.useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    slides: images.length,
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      images.length > 1 ? setOpacities(newOpacities) : null
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)

    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <>
      {mobileImage ? (
        <Helmet>
          <link
            rel="preload"
            as="image"
            href={mobileImage.src}
            imageSrcSet={mobileImage.srcSet}
          />
        </Helmet>
      ) : null}

      <Container variant={variant}>
        <style type="text/css">{mediaStyle}</style>
        <MediaContextProvider>
          <Media lessThan="ipadVertical">
            <Picture decoding="async" {...mobileImage} />
          </Media>
          <Media greaterThanOrEqual="ipadVertical">
            <Spinner variant="simple" />
            <Slider ref={sliderRef}>
              {uniq(images).map((item, index) => (
                <Slide
                  key={index}
                  className={currentSlide === index ? 'active' : undefined}
                  style={
                    index !== 0
                      ? {
                          opacity: opacities[index],
                        }
                      : { opacity: 1 }
                  }
                >
                  <Image
                    {...item}
                    alt={loaded[index] ? item.alt : undefined}
                    media="(min-width: 2800px)"
                    src={loaded[index] ? item.src : undefined}
                    srcSet={loaded[index] ? item.srcSet : undefined}
                  />
                </Slide>
              ))}
            </Slider>
            {variant === 'default' ? (
              <>
                {images.length > 1 ? (
                  <Dots>
                    {images.map((item, index) => (
                      <Dot
                        key={index}
                        className={
                          currentSlide === index ? 'active' : undefined
                        }
                        onClick={() => {
                          instanceRef.current?.moveToIdx(index)
                        }}
                      />
                    ))}
                  </Dots>
                ) : null}
              </>
            ) : null}
          </Media>
        </MediaContextProvider>
        {payoff ? (
          <Wrapper>
            {claim ? <Claim>{claim}</Claim> : null}
            <Payoff className={claim ? 'xl' : undefined}>
              {payoff}
              <Line />
            </Payoff>
          </Wrapper>
        ) : null}
      </Container>
    </>
  )
})

const Container = styled.section<ContainerProps>`
  height: 100vh;
  max-height: -webkit-fill-available;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
  &:before {
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
  }
  &:after {
    background: linear-gradient(
      ${rgba(theme.colors.variants.fadeDark, 0.5)},
      ${rgba(theme.colors.variants.fadeDark, 0)},
      ${rgba(theme.colors.variants.fadeDark, 0.5)}
    );
  }

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          height: 50vh;
        `
    }
  }}
`

const Picture = styled(Image)`
  width: 100%;
  height: calc(100% - 4.375rem);
  overflow: hidden;
  position: absolute;
  top: 4.375rem;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Slider = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s ease-in-out;
  }
`

const Dots = styled.section`
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
  z-index: 4;
`

const Dot = styled.div`
  width: 1rem;
  height: 1rem;
  border: 0.125rem solid transparent;
  border-radius: 50%;
  cursor: pointer;
  margin: 0.4375rem 0;
  position: relative;
  transition: 0.2s ease-in-out;
  &.active {
    border-color: ${rgba(theme.colors.variants.neutralLight4, 0.4)};
    pointer-events: none;
    &:before {
      opacity: 1;
    }
  }
  &:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-radius: 50%;
    opacity: 0.4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-weight: 500;
  position: absolute;
  top: 50%;
  right: 10.556vw;
  left: 10.556vw;
  transform: translateY(-50%);
  z-index: 4;

  @media (max-width: 1199px) {
    left: 1.875rem;
  }

  @media (max-width: 767px) {
    right: 1.25rem;
    left: 1.25rem;
  }
`

const Claim = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.25rem;
  margin-bottom: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Payoff = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  line-height: 4rem;
  &.xl {
    max-width: 31.875rem;
    font-size: 4rem;
    line-height: 5rem;
  }

  > div {
    width: 5rem;
    background: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-top: 1.875rem;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;
    &.xl {
      font-size: 2.25rem;
      line-height: 2.875rem;
    }

    > div {
      margin-top: 1rem;
    }
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'compact'
